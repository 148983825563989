<template>
  <div class="base-data">
    <div class="title">基础资料</div>
    <div class="content">
      <div class="row">
        <span class="label">姓名：</span><span class="value">{{ username }}</span>
      </div>
      <div class="row">
        <span class="label">所属部门：</span>
        <template v-for="(d, i) in departmentName" :key="i">
          <i v-if="i !== 0"></i>
          <span class="value"> {{ d }}</span>
        </template>
      </div>
      <div class="row">
        <span class="label">性别：</span><span class="value">{{ sex }}</span>
      </div>
      <div class="row">
        <span class="label">手机号：</span><span class="value">{{ telephone }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted, toRefs } from 'vue';
import api from '@/services';

export default defineComponent({
  setup() {
    const baseData = reactive({
      username: '',
      departmentName: [],
      sex: '',
      telephone: ''
    });

    const userPersonalCenter = async () => {
      try {
        let { data, success } = await api.userPersonalCenter();
        if (success) Object.assign(baseData, data);
      } catch (error) {
        console.error('个人中心接口错误！');
      }
    };

    onMounted(() => {
      userPersonalCenter();
    });

    return {
      ...toRefs(baseData),
      userPersonalCenter
    };
  }
});
</script>

<style lang="less" scoped>
.base-data {
  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #007fff;
    line-height: 28px;
  }

  .content {
    margin-top: 38px;
  }
  .row {
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    i {
      margin-right: 11px;
      width: 6px;
      height: 6px;
      border-top: 1px solid #666;
      border-right: 1px solid #666;
      transform: rotate(45deg);
    }
  }
  .label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
  }

  .value {
    margin-right: 8px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  }
}
</style>
